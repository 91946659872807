@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,600;1,300;1,400&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, "Kanit", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

body{background-color:whitesmoke;font-family:"Kanit"}.app-container{padding:35px;margin-top:205px}.button{text-decoration:none;background-color:white;border-radius:4px;padding:8px;text-transform:uppercase;box-shadow:3px 3px 10px rgba(200,200,200,0.8);margin:0 !important;transition:300ms}.button--primary{background-color:#640064;color:white}.button--primary:hover{background-color:#500150}.container{min-height:100vh;-webkit-animation:fadeIn 2s ease;animation:fadeIn 2s ease;max-width:1200px;margin:auto;position:relative;padding:35px}@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}.active{font-weight:bold}@media (max-width: 700px){.container{margin-bottom:30px;padding:20px}.app-container{padding:10px;margin-top:100px}}

.appbar-container{display:flex;justify-content:space-between;align-items:center;padding:10px 21px}.appbar-container img{height:120px}.auth-buttons{position:absolute;right:35px;top:5px}.nav{display:flex;justify-content:center;position:relative}.inner-nav{min-height:60px;display:flex;flex-wrap:wrap;justify-content:center}.login-button{text-decoration:none}.burger-container{width:220px}.burger{display:none}@media (max-width: 700px){.burger{display:block}.donate-button{display:none}.inner-nav{display:none}.auth-buttons{position:absolute;right:15px;top:10px}.appbar-container img{height:60px}}.link{margin:5px 10px;text-decoration:none;font-family:"Kanit";font-size:1.1rem;color:#640064;cursor:pointer}.dropdown-tag{color:#640064;cursor:pointer;text-decoration:none;font-family:"Kanit"}.user-button{display:flex;justify-content:center;align-items:center;background-color:#640064;color:white;border-radius:100px;cursor:pointer;box-shadow:2px 2px 4px grey;width:40px;height:40px;object-fit:cover;overflow:hidden}.user-button img{width:40px}

.drawer-container{padding:33px;width:250px}.nav-menu{display:flex;flex-direction:column}.link{margin:2px 10px;text-decoration:none;font-family:"Kanit";color:#640064;cursor:pointer;margin-bottom:2rem}.dropdown-tag{color:#640064;cursor:pointer;text-decoration:none;font-family:"Kanit"}.logo{width:100%;margin:auto;margin-bottom:20px}.close-icon{display:flex;justify-content:flex-end}@media (max-width: 700px){.drawer-container{padding:15px;width:260px}.close-icon{margin-top:-10px}}


.contact-form{display:flex;flex-direction:column;margin:auto;margin-top:40px;min-width:300px}.input-container{width:100%}.social-container{display:flex;flex-direction:column;align-items:center;margin-top:24px}@media (max-width: 700px){.contact-form{min-width:100%}}

.spinner-container{-webkit-animation:3s fadeIn ease;animation:3s fadeIn ease;display:flex;justify-content:center;align-items:center}.lds-default{display:inline-block;position:relative;width:80px;height:80px}.lds-default div{position:absolute;width:6px;height:6px;background:#745c9a;border-radius:50%;-webkit-animation:lds-default 1.2s linear infinite;animation:lds-default 1.2s linear infinite}.lds-default div:nth-child(1){-webkit-animation-delay:0s;animation-delay:0s;top:37px;left:66px}.lds-default div:nth-child(2){-webkit-animation-delay:-0.1s;animation-delay:-0.1s;top:22px;left:62px}.lds-default div:nth-child(3){-webkit-animation-delay:-0.2s;animation-delay:-0.2s;top:11px;left:52px}.lds-default div:nth-child(4){-webkit-animation-delay:-0.3s;animation-delay:-0.3s;top:7px;left:37px}.lds-default div:nth-child(5){-webkit-animation-delay:-0.4s;animation-delay:-0.4s;top:11px;left:22px}.lds-default div:nth-child(6){-webkit-animation-delay:-0.5s;animation-delay:-0.5s;top:22px;left:11px}.lds-default div:nth-child(7){-webkit-animation-delay:-0.6s;animation-delay:-0.6s;top:37px;left:7px}.lds-default div:nth-child(8){-webkit-animation-delay:-0.7s;animation-delay:-0.7s;top:52px;left:11px}.lds-default div:nth-child(9){-webkit-animation-delay:-0.8s;animation-delay:-0.8s;top:62px;left:22px}.lds-default div:nth-child(10){-webkit-animation-delay:-0.9s;animation-delay:-0.9s;top:66px;left:37px}.lds-default div:nth-child(11){-webkit-animation-delay:-1s;animation-delay:-1s;top:62px;left:52px}.lds-default div:nth-child(12){-webkit-animation-delay:-1.1s;animation-delay:-1.1s;top:52px;left:62px}@-webkit-keyframes lds-default{0%,20%,80%,100%{-webkit-transform:scale(1);transform:scale(1)}50%{-webkit-transform:scale(1.5);transform:scale(1.5)}}@keyframes lds-default{0%,20%,80%,100%{-webkit-transform:scale(1);transform:scale(1)}50%{-webkit-transform:scale(1.5);transform:scale(1.5)}}@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}

.footer-container{background-color:#4d4b4b;color:white;min-height:60px;display:flex;align-items:flex-end;justify-content:space-between;padding:10px 20px;text-align:right}.footer-container a{color:white;text-decoration:none}.footer-container a:hover{color:wheat}.footer-container__modal-toggle{cursor:pointer}.footer-container__modal-toggle:hover{color:wheat}

.homepage-container{min-height:100vh}.banner{display:flex;flex-direction:column;align-items:center;background-color:#eee;padding:15px;border-radius:20px;margin:30px 0}.homepage__intro{display:flex;flex-wrap:wrap;justify-content:space-evenly}.homepage__intro__text{flex:1 1;min-width:250px;padding:40px}.homepage__intro__image{width:50%;min-width:250px}.homepage__intro__image img{width:100%}

.conference-card-container{position:relative;background-color:#eee;padding:2rem;border-radius:1rem;margin:21px auto}.conference-card{margin-top:16px;max-width:1000px;display:flex}.conference-card__title{padding-bottom:16px}.conference-card__venue{padding-bottom:16px}.conference-card__image{width:100px;object-fit:cover;margin-right:26px;border-radius:12px}.conference-card__mobile-image{display:none}.more-info-link{margin-top:8px}.buttons-container{display:flex;width:100%;justify-content:space-between;margin-bottom:8px}@media (max-width: 700px){.conference-card-container{text-align:center;padding:1rem}.conference-card-container a{white-space:pre-wrap;white-space:-moz-pre-wrap;white-space:-pre-wrap;white-space:-o-pre-wrap;word-wrap:break-word}.conference-card-container h5{font-size:1.1rem}.conference-card-container h4{font-size:1.5rem;text-align:center}.conference-card-container .conference-card{text-align:center}.conference-card-container .conference-card a{max-width:100%}.conference-card-container .conference-card__image{display:none}.conference-card-container .conference-card__mobile-image{display:flex;justify-content:flex-end;height:100px}.mobile-image-container{width:100%;overflow:hidden;border-radius:100px;margin-bottom:16px}.mobile-image-container img{width:100%}.buttons-container{justify-content:space-evenly}.more-info-link{margin:16px 0}.donations-advice{text-align:center}}

.grid-wrapper>div{display:flex;justify-content:center;align-items:center;height:100%}.grid-wrapper{display:flex;flex-wrap:wrap;justify-content:center;padding-top:60px}.image-container{position:relative;width:200px;height:200px;border-radius:5px;margin:5px;overflow:hidden;cursor:pointer;transition:300ms}.image-container:hover{-webkit-filter:saturate(0.3);filter:saturate(0.3)}.image-container img{width:200px;height:200px;object-fit:cover}.image-container__description{position:absolute;bottom:0;left:0;right:0;padding:10px;color:white;font-size:0.9rem;background-color:rgba(0,0,0,0.7)}

.page-title-container{display:flex;justify-content:space-between}.sharing-buttons-container{display:flex;justify-content:flex-end}@media (max-width: 1050px){.page-title-container{text-align:center}.page-title{font-size:1.7rem}}

.button{margin:2rem}

.proposals-form{display:flex;flex-direction:column}.proposals-modal{padding:25px}.input{margin-bottom:21px;width:500px}

.past-event-list-item{display:flex;background-color:#eee;border-radius:12px;padding:8px;margin:8px}.past-event-list-item:hover{background-color:#ccc}@media (max-width: 700px){.past-event-list-item{justify-content:space-around}.past-event-list-item h6{font-size:1rem}}

.header-section{display:flex;justify-content:space-between;margin-top:24px}.header-section__image{border-radius:12px}.conference-videos-container{display:flex;justify-content:center;flex-wrap:wrap}.conference-video-card{margin:20px;width:400px;height:200px;min-width:400px;border-radius:12px;overflow:hidden;object-fit:cover}.conference-video-card iframe{width:100%;height:120%}.video-title{text-align:center}.conference-photos-gallery{display:flex;flex-wrap:wrap;justify-content:center;margin-top:35px}.conference-photos-gallery-image{width:20%;margin:20px;word-wrap:break-word}.conference-photos-gallery-image img{width:100%}

.download-link{color:steelblue;text-decoration:none}.download-link:hover{color:#2d587a}

.accordion-header{display:flex;align-items:center;justify-content:space-between;padding:10px;cursor:pointer}.accordion-header:hover{background-color:#e3e3e3;border-radius:10px;transition:200ms}.accordion-header--toggle-button{cursor:pointer;transition:300ms;margin-right:8px}.accordion-header--dark{background-color:#acacac;background-color:#640064;display:flex;align-items:center;justify-content:space-between;color:white;border-radius:10px;padding:10px;cursor:pointer}.accordion-header--dark:hover{background-color:#696666;transition:200ms}.accordion-content{overflow:hidden;transition:200ms}.accordion-open{transition:200ms}

.profile-registration-form{display:flex;flex-direction:column}.inputs-bar{display:flex;justify-content:space-evenly}.input-container{width:100%;max-width:500px;margin:10px auto}

.update-profile-form{display:flex;flex-direction:column;padding:35px;width:50%}.update-avatar-form{position:relative;display:flex;flex-direction:column;align-items:center;justify-content:space-between;padding:35px;width:50%}.profile-pane{display:flex;justify-content:space-between}.update-profile-pane{border:1px solid #eee;border-radius:20px;margin-top:35px;display:flex;justify-content:space-between}.avatar-container{margin:0 35px;border-radius:250px;height:150px;width:150px;overflow:hidden}.avatar-container img{width:100%}

.member-cards-container{display:flex;flex-wrap:wrap;justify-content:center}.member-card{display:flex;align-items:center;border:1px solid #ddd;background-color:#eee;padding:12px;border-radius:12px;margin:16px;width:330px}.member-card__avatar{width:58px;height:58px;border-radius:50px;box-shadow:2px 2px 4px grey;margin-right:10px;overflow:hidden}.member-card__avatar img{min-width:100%;height:100%;object-fit:cover}.member-card:hover{background-color:#eee;cursor:pointer}.avatar-placeholder{background-color:#ccc;width:58px;height:58px;border-radius:50px;display:flex;align-items:center;justify-content:center;color:white;background-color:#640064;box-shadow:2px 2px 4px grey;margin-right:16px}.members-list-info{padding:0 4px;border-left:1px solid #ccc}.chip{color:white;background-color:black;border-radius:12px;padding:1px 6px}

.details-modal{padding:35px}.details-modal__list-title{font-weight:700;margin-bottom:-10px;margin-top:5px}.details-modal__avatar{height:100px;width:100px;overflow:hidden;border-radius:15px}.details-modal__avatar img{object-fit:cover;min-width:100%;height:100%}

.pdf-scroll-window{height:300px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;overflow:auto;margin-top:21px}

.about-page-section{display:flex;flex-wrap:wrap;justify-content:space-between;width:100%}.about-page-section__text{width:60%;min-width:250px}.about-page-section__image{width:30%;min-width:250px;margin:20px}.about-page-section__image img{width:100%;border-radius:12px}

.proposals-form{display:flex;flex-direction:column}.proposals-modal{padding:25px}.input{margin-bottom:21px;width:500px}@media (max-width: 700px){.input{width:100%}}

.competition-card{width:70%;background-color:#eee;border-radius:12px;margin:35px auto;padding:35px;display:flex;flex-direction:column;align-items:center;justify-content:center}.competition-card__button{margin-top:30px}@media (max-width: 700px){.competition-card{text-align:center}.competition-card h5{font-size:1.3rem}}


.competition__details{display:flex;margin-top:30px}.competition__details__text{flex:1 1;padding:30px}.competition__details__image{width:50%}.competition__details__image img{width:100%}@media (max-width: 900px){.competition__details{flex-direction:column-reverse}.competition__details__image{width:100%}.competition__details__text{padding:0}}

.tabs-container{margin:20px 0;border:1px solid #ddd;border-radius:5px}.tabs__row{background-color:#ddd;overflow-x:auto;display:flex}.tabs__row button{border:none;background-color:#ddd;cursor:pointer;border-right:1px solid #ccc;border-radius:5px 5px 0 0;padding:9px;white-space:nowrap}.tabs__content{padding:5px}.tabs__item--selected{background-color:whitesmoke !important}

.competition-form{display:flex;flex-direction:column;align-items:center;margin-top:16px}.competition-form__container{text-align:center}.competition-form__input{margin-bottom:21px;margin-top:5px;max-width:500px;width:100%;background-color:white}.competition-form__disclaimer{margin-top:-10px;margin-bottom:20px;color:#640064}.competition-form__radio{margin:12px 0}.competition-form__radio span{margin-left:8px}.competition-form__radio-group{background-color:white;border-radius:10px;border:1px solid #dddddd;padding-left:10px;margin-bottom:30px}.proposals-modal{padding:25px}@media (max-width: 700px){.input{width:100%}}

.table{border-collapse:collapse;margin:auto}.table th{color:#640064;background-color:#ddd}.table td,.table th{padding:5px 10px;border:1px solid #ddd}.table__title{text-align:center;color:#640064}@media (max-width: 700px){.table td,.table th{padding:2px;font-size:smaller}}

.notfound-container{background-color:#640064;color:white;border-radius:12px;display:flex;flex-direction:column;align-items:center;padding:35px}.notfound-container button{color:white}

@media (min-width: 768px){.journals{display:grid;grid-template-columns:1fr 2fr;grid-template-areas:"image1 text1" "image1 image2" "image1 text2"}}.journals__image{mix-blend-mode:multiply;width:60%}@media (max-width: 768px){.journals__image{width:100%;margin:20px 0}}.journals__image__wrapper{width:100%}.journals__image__wrapper--centered{display:flex;justify-content:center}.image1{grid-area:image1}@media (max-width: 768px){.image1{width:30%;margin:auto}}.image2{grid-area:image2;margin:auto}.text1{grid-area:text1}.text2{grid-area:text2}

.modal-container{padding:30px}

.photo-upload-container{border-radius:4px;padding:20px}

.disclaimer{color:#bbb;margin-top:20px;margin-bottom:-20px;text-align:center}.form{position:relative;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border:1px solid #ccc;padding:30px;border-radius:20px;margin:35px auto}

.privacy-modal{padding:20px}

.admin-input{padding:10px;margin:10px;border-radius:5px;border:1px solid #ccc;outline:none}.admin-button{outline:none;border:1px solid #ccc;padding:10px;border-radius:5px;cursor:pointer}.admin-button-container{display:flex;justify-content:space-evenly;margin:30px}.create{background-color:yellowgreen}.delete{background-color:#ff9090}

.donations-banner{text-align:center;margin:0;border-radius:0}

.scholarship-card-container{position:relative;background-color:#eee;padding:2rem;border-radius:1rem;margin:21px auto}.scholarship-card{margin-top:16px;max-width:1000px;display:flex}.scholarship-card__title{padding-bottom:16px}.scholarship-card__intro{padding-bottom:16px}.more-info-link{margin-top:8px}@media (max-width: 700px){.scholarship-card-container{text-align:center;padding:1rem}.scholarship-card-container a{white-space:pre-wrap;white-space:-moz-pre-wrap;white-space:-pre-wrap;white-space:-o-pre-wrap;word-wrap:break-word}.scholarship-card-container h5{font-size:1.1rem}.scholarship-card-container h4{font-size:1.5rem;text-align:center}.scholarship-card-container .scholarship-card{text-align:center}.scholarship-card-container .scholarship-card a{max-width:100%}.scholarship-card-container .scholarship-card__image{display:none}.scholarship-card-container .scholarship-card__mobile-image{display:flex;justify-content:flex-end;height:100px}.mobile-image-container{width:100%;overflow:hidden;border-radius:100px;margin-bottom:16px}.mobile-image-container img{width:100%}}

.instrument-page-text{padding-bottom:40px;margin-right:20px}@media (max-width: 768px){.instrument-page-text{margin-right:0}}.instrument-page-container{display:flex}@media (max-width: 768px){.instrument-page-container{flex-direction:column}}.instrument-page-container__payment{background-color:#640064;padding:10px;border-radius:10px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;margin-bottom:40px}.instrument-card{background-color:#eee;padding:20px;border-radius:20px;margin:24px 0}.instrument-card__date{font-size:1.2rem;margin-bottom:16px;display:block}.instrument-card__make{color:#640064;font-size:1.2rem}.instrument-card__price{margin:16px 0;color:#640064}.instrument-card__description{border-top:1px solid #ddd}.instrument-card__details{display:flex}@media (max-width: 768px){.instrument-card__details{flex-direction:column}}.instrument-card__meta{display:block}.instrument-card__text{flex:1 1}.instrument-card__images{width:50%}@media (max-width: 768px){.instrument-card__images{width:100%}}.instrument-card__seller{border-top:1px solid #ddd;padding-top:12px}

