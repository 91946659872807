@import "../../variables.scss";

.instrument-page-text {
  padding-bottom: 40px;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.instrument-page-container {
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  &__payment {
    background-color: $primary;
    padding: 10px;
    border-radius: 10px;
    height: fit-content;
    margin-bottom: 40px;
  }
}

.instrument-card {
  background-color: #eee;
  padding: 20px;
  border-radius: 20px;
  margin: 24px 0;

  &__date {
    font-size: 1.2rem;
    margin-bottom: 16px;
    display: block;
  }

  &__make {
    color: $primary;
    font-size: 1.2rem;
  }

  &__price {
    margin: 16px 0;
    color: $primary;

    // font-weight: 600;
    // font-size: 1.1rem;
  }

  &__description {
    border-top: 1px solid #ddd;
  }

  &__details {
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__meta {
    display: block;
  }

  &__text {
    flex: 1;
  }

  &__images {
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__seller {
    border-top: 1px solid #ddd;
    padding-top: 12px;
  }
}
